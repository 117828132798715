// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    line-height: 1.5;
}
a{
    display: block;
    text-decoration: none;
    color: black;
    width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/reset.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,yCAAyC;IACzC,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n    font-family: Arial, Helvetica, sans-serif;\n    box-sizing: border-box;\n    line-height: 1.5;\n}\na{\n    display: block;\n    text-decoration: none;\n    color: black;\n    width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
