// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prt_theme_icon{
    border: none;
    opacity: 0;
    transition:opacity 90ms ease-out;
}
.prt_theme_hover_button:hover>.prt_theme_icon{
    opacity: 1;
    transition: opacity 150ms ease-in;
}`, "",{"version":3,"sources":["webpack://./src/components/themeButton/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,gCAAgC;AACpC;AACA;IACI,UAAU;IACV,iCAAiC;AACrC","sourcesContent":[".prt_theme_icon{\n    border: none;\n    opacity: 0;\n    transition:opacity 90ms ease-out;\n}\n.prt_theme_hover_button:hover>.prt_theme_icon{\n    opacity: 1;\n    transition: opacity 150ms ease-in;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
